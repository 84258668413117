import BlogComponent from "../../components/BlogComponent"

const Blogs = () => {
  return (
    <div>
        <BlogComponent/>
    </div>
  )
}

export default Blogs